import { LoadingOutlined, RollbackOutlined, UploadOutlined } from '@ant-design/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, DatePicker, Form, Row } from 'antd'
import { RcFile } from 'antd/es/upload'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import dayjs from 'dayjs'

import {
  CustomInput,
  CustomSelect,
  ModalTagCreation,
  QuillEditor,
  SeoConfig,
  TextAreaCustom,
  Title,
  UploadFileWrap,
  UploadWrap
} from '@components'
import { PostStatus, ROUTERS, defaultSocials } from '@defines'
import {
  useController,
  useListFields,
  useListIndustries,
  useListPostsCategory,
  useListTags,
  usePostAction,
  useUpload,
  useUploadPostFile
} from '@hooks'
import { PostCreationForm, PostSEO } from '@types'
import { autoGeneratePermanentLink, commonSlug } from '@utils'

import { SocialLinks } from './components'
import { FormItemSubmit, Header, UpMedia, UploadContent, Wrap, WrapPublishDate } from './styles'
import { handleErrorPost, handleParamsPost } from './post.func'

export const DetailPost = () => {
  const { postId } = useParams()
  const [content, setContent] = useState('')
  const [errorContent, setErrorContent] = useState('')
  const [publishDate, setPublishDate] = useState<any>(dayjs(new Date()))
  const [seoForm, setSeoForm] = useState<PostSEO>({})
  const [socialLinks, setSocialLinks] = useState(defaultSocials)
  const {
    handleUpload,
    imageUploaded,
    loading: uploadingImage,
    setImageUploaded,
    error: errorImage,
    setError: setErrorTHum
  } = useUpload()
  const {
    handleUploadFile,
    handleClearFile,
    fileUploaded,
    loading: uploadingFile,
    setFileUploaded
  } = useUploadPostFile()
  const { createPost, getDetailPost, detail, clearDetail, loading: loadingDetail, updatePost } = usePostAction()
  const { getListCategory, categoryOptions } = useListPostsCategory()
  const { getListTags, tagOptions } = useListTags()
  const { getListIndustries, industryOptions } = useListIndustries()
  const { getListFields, FieldOptions } = useListFields()
  const { t } = useTranslation()
  const { controller } = useController()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const currentParams = Object.fromEntries([...searchParams])
  const schema = yup.object().shape({
    title: yup.string().trim().required(t('message.error_required')).min(8),
    excerpt: yup.string().trim().required(t('message.error_required')),
    author: yup.string().trim().required(t('message.error_required')),
    post_categories: yup.array().required(t('message.error_required')).min(1, t('message.error_required')),
    slug: yup.string().trim().required(t('message.error_required')).min(8),
    permalink: yup.string().trim().required(t('message.error_required')).url('Invalid URL'),
    tags: yup.array().required(t('message.error_required')).min(1, t('message.error_required')),
    fields: yup.array().required(t('message.error_required')).min(1, t('message.error_required')),
    post_industries: yup.array().of(yup.string()).nullable().notRequired(),
    used_technologies: yup.string().nullable().notRequired()
  })

  const loading = uploadingImage || loadingDetail || uploadingFile

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    watch,
    getValues,
    setError,
    setValue
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      author: 'Admin',
      permalink: autoGeneratePermanentLink()
    },
    resolver: yupResolver(schema)
  })
  const watchTitle = watch('title')
  const watchCategory = watch('post_categories')

  const selectedCate = useMemo(() => {
    return categoryOptions?.find(i => i.value === watchCategory?.[0])
  }, [watchCategory, categoryOptions])

  useEffect(() => {
    if (watchTitle && !detail?.slug) {
      setValue('slug', commonSlug(watchTitle))
      setError('slug', { message: '' })
    }
  }, [watchTitle])

  useEffect(() => {
    if (postId) {
      getDetailPost(postId)
    } else if (searchParams.get('catId')) {
      setValue('post_categories', [Number(searchParams.get('catId'))])
    }
    return () => {
      clearDetail()
    }
  }, [postId])

  useEffect(() => {
    if (detail) {
      setValue('title', detail?.title || '')
      setValue('excerpt', detail?.excerpt || '')
      setValue('slug', detail.slug ? detail.slug : commonSlug(detail.title))
      setValue('permalink', detail?.permalink || autoGeneratePermanentLink(selectedCate?.label, detail.slug))
      setValue('post_categories', detail?.categories?.map(i => i.id) || [])
      setValue('tags', detail?.tags?.map(i => i.id) || [])
      setValue('fields', detail?.fields?.map(i => i.id) || [])
      setValue('author', detail?.author || '')
      setValue('post_industries', detail?.industries?.map(i => i.id) || [])
      setValue('used_technologies', detail?.used_technologies || '')
      setContent(detail?.content || '')
      setImageUploaded(detail?.images?.[0])
      setFileUploaded(detail?.files?.[0])
      setSocialLinks(detail?.social_links || defaultSocials)
      if (detail?.publish_date) setPublishDate(dayjs(detail?.publish_date))
    }
  }, [detail])

  useEffect(() => {
    getListCategory({ limit: 999 })
    getListTags({ limit: 999 })
    getListFields({ limit: 999 })
    getListIndustries({ limit: 999 })
  }, [])

  const navigateKeepParams = (pathname: string) => {
    navigate({ pathname, search: `?${createSearchParams(currentParams)}` })
  }

  const scrollToError = () => {
    if (!isSubmitted) {
      return
    }
    handleErrorPost({
      errors,
      errorImage,
      errorContent
    })
  }

  const handleForm = async (formData: any) =>
    controller(async () => {
      const onSuccess = () => {
        navigateKeepParams(ROUTERS.POSTS)
      }
      const params = handleParamsPost({
        formData,
        imageUploaded,
        setErrorTHum,
        setErrorContent,
        content,
        fileUploaded,
        selectedCate,
        socialLinks,
        seoForm,
        detail,
        scrollToError,
        publishDate
      })
      if (!params) {
        return
      }
      if (postId) {
        updatePost(postId, params, { onSuccess })
      } else {
        createPost(params, { onSuccess })
      }
    })

  const onSubmit = handleSubmit(handleForm)

  const filterOption = (input: string, option?: { label: string; value: number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  useEffect(() => {
    const errorKeys = Object.keys(errors)
    if (errorKeys?.length) {
      scrollToError()
    }
  }, [errors])

  return (
    <Wrap>
      <Header>
        <Button onClick={() => navigateKeepParams(ROUTERS.POSTS)}>
          <RollbackOutlined />
          Back
        </Button>
      </Header>
      <Row>
        <Col xs={18}>
          <Title>Detail Post</Title>
        </Col>
        <Col xs={6}>
          <WrapPublishDate>
            <div>Publish date</div>
            <DatePicker value={publishDate} onChange={e => setPublishDate(e)} />
          </WrapPublishDate>
        </Col>
      </Row>
      <div>
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: true
          }}
          onFinish={onSubmit}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              e.preventDefault()
              return
              // const values: PostCreation = getValues()
              // handleSubmit(() => handleForm(values))
            }
          }}
        >
          <CustomInput
            control={control}
            type='string'
            name='title'
            placeholder='Title'
            label='Title'
            required
            maxLength={500}
            minLength={8}
            error={errors.title?.message}
            size='large'
            mb={12}
          />
          <TextAreaCustom
            control={control}
            type='string'
            name='excerpt'
            required
            label={t('Your excerpt')}
            placeholder={t('Your excerpt')}
            maxLength={1000}
            size='large'
            mb={24}
            error={errors.excerpt?.message}
            autoSize={{
              minRows: 4,
              maxRows: 4
            }}
            radius={8}
          />
          <CustomSelect
            id='post_categories'
            control={control}
            label='Category'
            required
            size='large'
            options={categoryOptions}
            mode='multiple'
            filterOption={filterOption as any}
            mb={16}
            name={`post_categories`}
            placeholder='Select Category'
            error={errors.post_categories?.message}
          />
          <Row>
            <Col xs={18}>
              <CustomSelect
                id='tags'
                control={control}
                label='Tags'
                size='large'
                options={tagOptions}
                mb={16}
                filterOption={filterOption as any}
                showSearch
                required
                mode='multiple'
                name={`tags`}
                placeholder='Select Tags'
                error={errors.tags?.message}
              />
            </Col>
            <Col xs={6}>
              <ModalTagCreation />
            </Col>
          </Row>

          <CustomSelect
            id='fields'
            control={control}
            label='Fields'
            size='large'
            options={FieldOptions}
            filterOption={filterOption as any}
            showSearch
            mb={16}
            required
            mode='multiple'
            name={`fields`}
            placeholder='Select Fields'
            error={errors.fields?.message}
          />
          <CustomSelect
            id='post_industries'
            control={control}
            label='Industries'
            size='large'
            options={industryOptions}
            filterOption={filterOption as any}
            showSearch
            mb={16}
            mode='multiple'
            name={`post_industries`}
            placeholder='Select industries'
          />
          <CustomInput
            control={control}
            type='string'
            name='used_technologies'
            label={t('Technologies')}
            placeholder={t('Enter technologies')}
            error={errors.used_technologies?.message}
            maxLength={100}
            radius={8}
            size='large'
          />
          <CustomInput
            control={control}
            type='string'
            name='permalink'
            required
            label={t('Permalink')}
            placeholder={t('Permalink')}
            error={errors.permalink?.message}
            maxLength={100}
            radius={8}
            size='large'
          />
          <Row gutter={[20, 20]}>
            <Col xs={12}>
              <UploadWrap error={errorImage} src={imageUploaded?.path} onChange={e => handleUpload(e as RcFile)}>
                <UpMedia>
                  <UploadContent id='error-image'>
                    <UploadOutlined className='upload-icon' />
                    {imageUploaded?.path ? 'Edit' : 'Add'} Thumnail (Image) <span className='required'>*</span>{' '}
                    {loading ? <LoadingOutlined className='loading-icon' /> : null}
                  </UploadContent>
                </UpMedia>
              </UploadWrap>
            </Col>
            <Col xs={12}>
              <UploadFileWrap
                src={fileUploaded?.path}
                onChange={e => handleUploadFile(e as RcFile)}
                onClear={() => handleClearFile()}
              >
                <UpMedia>
                  <UploadOutlined />
                  {fileUploaded?.path ? 'Edit' : 'Add'} Paper Document (PDF)
                  {loading ? <LoadingOutlined className='loading-icon' /> : null}
                </UpMedia>
              </UploadFileWrap>
            </Col>
          </Row>
          <br id='error-content' />
          <QuillEditor
            value={content}
            label='Content'
            required
            error={errorContent}
            onChange={e => {
              setErrorContent('')
              setContent(e)
            }}
          />
          <br />
          <CustomInput
            control={control}
            type='string'
            name='author'
            required
            placeholder='Author'
            label='Author'
            maxLength={500}
            error={errors.author?.message}
            size='large'
            mb={12}
          />
          <CustomInput
            control={control}
            type='string'
            name='slug'
            required
            placeholder='Slug'
            label='Slug'
            maxLength={500}
            error={errors.slug?.message}
            size='large'
            mb={12}
          />
          <SocialLinks defaultValues={detail?.social_links} onChange={values => setSocialLinks(values)} />
          <SeoConfig initialSeo={detail?.seo} onChange={e => setSeoForm(e)} />
          <FormItemSubmit>
            <Button type='dashed' loading={loading} htmlType='submit' size='large' className='save-draft'>
              {detail?.id ? 'Save' : 'Save as Draft'}
            </Button>
            <Button
              type='primary'
              size='large'
              loading={loading}
              onClick={() => {
                const values = getValues()
                handleSubmit(() => handleForm({ ...values, status: PostStatus.Publish } as PostCreationForm))()
              }}
            >
              {detail && Number(detail?.status) === PostStatus.Publish ? 'Update' : 'Publish'}
            </Button>
          </FormItemSubmit>
        </Form>
      </div>
    </Wrap>
  )
}
