import { message as messageApi } from 'antd'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

import { RESPONSE_CODE, RESPONSE_MESSAGE } from '@defines'

import { useLogout } from '../user'

type Handle<T = unknown> = () => Promise<T>

type Options = {
  onLoading?: (value: boolean) => void
  onSuccess?: () => void
  onError?: (error: unknown) => void
  onDone?: () => void
  notice?: {
    show?: boolean
    ignoreCodes?: number[]
    fallbackMessage: string
  }
}

export const useController = () => {
  const { logout } = useLogout()
  const { t } = useTranslation()

  const navigateUnauthen = (error: any) => {
    messageApi.error(error?.response?.data?.detail || t('error.unauthorized'))
    logout()
  }

  const controller = async <T = unknown>(handle: Handle<T>, options?: Options): Promise<T | undefined> => {
    const { notice: noticeOptions, onDone, onSuccess, onError, onLoading } = options || {}
    const isShowNotice = noticeOptions?.show
    const fallbackMessage = noticeOptions?.fallbackMessage
    // const noticeIgnoreCode = noticeOptions?.ignoreCodes || []
    let result: T | undefined

    try {
      if (onLoading) onLoading(true)
      result = await handle()
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onError) onError(error)

      if (error instanceof AxiosError) {
        const { response } = error
        // eslint-disable-next-line
        const status: number | undefined = response?.status
        // eslint-disable-next-line
        // const resCode: RESPONSE_CODE = response?.data?.message
        if (
          status === 401
          // || resCode === RESPONSE_CODE.TOKEN_EXPIRIED
        ) {
          navigateUnauthen(error)
          return
        }
        if (status === RESPONSE_CODE.USED) {
          messageApi.error('This entity is being used')
          return
        }
      }

      if (isShowNotice !== false) {
        let message = ''

        if (error instanceof AxiosError) {
          const status: number | undefined = error.response?.status || RESPONSE_CODE.INTERNAL_SERVER_ERROR
          const messageResponse = error.response?.data?.message || (RESPONSE_MESSAGE as any)[status]

          if (messageResponse) {
            message = t(messageResponse)
          } else if (status === 401) {
            message = t('error.unauthorized')
          } else {
            message = error.message
          }
          if ((error?.response?.data as any)?.detail) {
            message = (error?.response?.data as any)?.detail
          }
        } else if (fallbackMessage) {
          message = fallbackMessage
        } else if (error instanceof Error) {
          message = error.message
        } else if (typeof error === 'string') {
          message = error
        } else message = t('unknow_error')

        if (message) messageApi.error(message)
      }
    } finally {
      if (onLoading) onLoading(false)
      if (onDone) onDone()
    }

    return result
  }

  return { controller, navigateUnauthen }
}
