/*eslint-disable */
export enum RESPONSE_CODE {
  INTERNAL_SERVER_ERROR = 500,
  UNAUTHORIZED = 401,
  USED = 423
}

export const RESPONSE_MESSAGE = {
  [RESPONSE_CODE.INTERNAL_SERVER_ERROR]: 'response_code.internal_server_error'
}
/* eslint-enable */
