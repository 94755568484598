import { CloseCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Button } from 'antd'
import { RcFile } from 'antd/es/upload'
import React, { useState, ChangeEvent, DragEvent, useEffect, useRef } from 'react'

const Wrap = styled.div`
  .error {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.danger};
  }
`
const WrapPreviewFile = styled.div`
  border: solid 0.5px gray;
  position: relative;

  .btn-clear {
    width: 16px;
    height: 16px;
    margin: 0px;
    padding: 0px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    color: red;
  }

  .btn-clear:hover {
    color: red;
    opacity: 0.8;
  }

  iframe {
    height: 338px;
    width: 100%;
  }
`

type ImageUploaderProps = {
  children?: React.ReactElement
  src?: string
  error?: string
  onChange?: (file: File | RcFile) => void
  onClear?: () => void
}

const UploadArea = styled.div`
  input {
    opacity: 0;
    height: 0px !important;
  }
  display: inline-block;
`

export const UploadFileWrap: React.FC<ImageUploaderProps> = ({ children, error, src, onChange, onClear }) => {
  const [selectedFile, setSelectedFile] = useState<string | null>(null)
  const uploadRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (src) {
      setSelectedFile(src)
    }
  }, [src])

  const previewImage = (file: File | null) => {
    if (file) {
      onChange?.(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedFile(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    previewImage(file || null)
  }

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.files?.[0]
    previewImage(file)
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleClear = () => {
    if (!onClear) return
    onClear()
    previewImage(null)
    setSelectedFile(null)
    if (uploadRef.current) {
      uploadRef.current.value = ''
    }
  }

  return (
    <Wrap>
      <UploadArea onDrop={handleDrop} onDragOver={handleDragOver}>
        <div onClick={() => uploadRef?.current?.click?.()}>{children}</div>
        <input type='file' accept='application/pdf' ref={uploadRef as any} onChange={handleFileChange} hidden={true} />
      </UploadArea>
      {error ? <div className='error'>{error}</div> : null}
      {selectedFile && (
        <WrapPreviewFile>
          <Button type='text' className='btn-clear' icon={<CloseCircleOutlined />} onClick={handleClear}></Button>
          <iframe src={selectedFile}></iframe>
        </WrapPreviewFile>
      )}
    </Wrap>
  )
}
