import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { useState } from 'react'

// import { userApi } from '@api'
import { useController } from '@hooks'
import { postApi } from '@api'
import { ThumnailImageModel, UploadImageRes } from '@types'

export const useUploadPostFile = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const [fileUploaded, setFileUploaded] = useState<ThumnailImageModel | undefined>()
  const { controller } = useController()

  const handleChangeFile: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    setLoading(false)
  }

  const deleteAvatar = async (options?: { onSuccess?: () => void }) => {
    controller(
      async () => {
        setLoading(true)
        // await UserApi.deleteAvatarRequest()

        options?.onSuccess?.()
        return true
      },
      {
        onDone: () => setLoading(false)
      }
    )
  }

  const handleUploadFile = async (e: RcFile, options?: { onSuccess?: (res?: UploadImageRes) => void }) => {
    controller(
      async () => {
        setError('')
        setLoading(true)
        const formData = new FormData()
        formData.append('file', e)
        const res = await postApi.uploadPostFile(formData)
        setFileUploaded(res?.data)
        options?.onSuccess?.(res?.data)
      },
      {
        onDone: () => setLoading(false)
      }
    )
  }

  const handleClearFile = async () => {
    controller(
      async () => {
        setError('')
        setLoading(true)
        setFileUploaded(undefined)
      },
      {
        onDone: () => setLoading(false)
      }
    )
  }

  return {
    handleChangeFile,
    handleUploadFile,
    handleClearFile,
    error,
    setError,
    deleteAvatar,
    fileUploaded,
    setFileUploaded,
    loading
  }
}
